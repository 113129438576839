@charset "UTF-8";
nav {
  position: absolute;
  left: 0;
  top: 0;
}

.navbar {
  padding: 0rem 1rem;
  min-height: 45px;
  background-color: white;
  -webkit-box-shadow: 0px -0.5em 0.5em 0.5em rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px -0.5em 0.5em 0.5em rgba(0, 0, 0, 0.5);
  box-shadow: 0px -0.5em 0.5em 0.5em rgba(0, 0, 0, 0.5);
}

.navbar-absolute {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.navbar a,
.navbar p {
  color: #333;
}

.navbar a:hover,
.navbar .nav-active-item {
  color: #2748ff;
}

.nav-link {
  font-weight: 500;
}

.navbar .navbar-nav .nav-item p {
  margin-bottom: 0px;
}

.navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.pagination {
  position: relative;
  margin: auto;
}

.down-button {
  position: absolute;
  bottom: 87px;
  width: 100%;
  height: 0px;
}

.down-button a {
  color: #2748ff;
  transition: color 0.2s;
}

.down-button a:hover {
  color: white;
}

.animated {
  display: inline-block;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-25px);
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-25px);
  }
}
.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
}

.features {
  background-color: #01116a;
  color: white;
}

.features a {
  color: white;
}

.features img {
  border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  border: 1px solid #606060;
}

.features h1,
.features h3 {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
}

.features p {
  font-family: Helvetica, Arial, sans-serif;
  color: white;
}

.cover-bottom {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.home-community {
  width: 100%;
  background-image: url("/img/cover_bottom.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-community h1,
.home-community h3 {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
}

.home-community li,
.home-community p {
  font-family: Helvetica, Arial, sans-serif;
}

.bold {
  font-weight: 700;
}

.red,
.red a,
.red p {
  color: #af0000;
}

.footer {
  width: 100%;
  padding-top: 1em;
  background-color: #01116a;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
}

.footer,
footer a {
  color: white;
}

.footer img {
  width: 30px;
  height: 30px;
}

footer a:hover {
  color: white;
  text-decoration: none;
}

@media (max-width: 576px) {
  .footer {
    text-align: center;
  }
}
.database-record {
  text-align: center;
}

.database-record td {
  vertical-align: middle;
}

.youtube-frame {
  text-align: center;
}

.drive-fields {
  margin-top: 30px;
}

.road-preview img {
  height: 70px;
}

.selected-atlas-item {
  background-color: #003df538;
  border-top: 1px solid #2748ff;
  border-bottom: 1px solid #2748ff;
}

.atlas-list-item:hover {
  background-color: #003df538;
}

.slick-initialized .slick-slide {
  float: none;
  display: inline-block;
  vertical-align: middle;
}

.slick-prev:focus:before,
.slick-next:focus:before,
.slick-prev:before,
.slick-next:before {
  background: white;
  color: black;
  opacity: 0.8;
}

.slick-prev:hover:before,
.slick-next:hover:before {
  color: #2748ff;
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  color: black;
  opacity: 0.3;
}

.slick-slide img {
  margin: 0 auto;
}

.carousel-item-loading {
  width: 200px;
}

.carousel-item-delete {
  position: absolute;
  display: inline-block;
  cursor: pointer;
  right: 0px;
  vertical-align: middle;
  width: 35px;
  height: 35px;
  border-radius: 17.5px;
  border: 1px solid #2748ff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;
  background-color: #2748ff;
  background-image: url("/img/bin-white.png");
}

.carousel-item-delete:hover {
  background-color: white;
  background-image: url("/img/bin-blue.png");
}

.pointer {
  cursor: pointer;
}

.gallery-lr-wrapping {
  padding-left: 30px;
  padding-right: 30px;
}

.gallery-slide-panel {
  width: 100%;
  height: 220px;
  padding-top: 10px;
  margin-bottom: 10px;
}

.comment-gallery-slide-panel {
  width: 100%;
  height: 115px;
  margin-bottom: 10px;
}

.atlas-list-item .profile-img {
  margin: 2.5px 5px;
  width: 100px;
  height: 100px;
}

.record-title {
  font-size: 1.3em;
  color: #2748ff;
}

.atlas-list-item .record-type {
  padding-right: 3px;
  padding-top: 3px;
  display: inline-block;
}

.atlas-list-item .record-type img {
  height: 1em;
  width: auto;
}

.atlas-road-list {
  overflow-y: scroll;
  height: calc(100vh - 45px);
}

@media (max-width: 991px) {
  .atlas-road-list {
    height: 100vh;
  }
}
.atlas-loading-panel {
  height: calc(100vh - 45px);
  vertical-align: middle;
}

.atlas-detail-close {
  float: right;
  display: inline-block;
  padding: 2px 5px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.vertical-center-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#home-page-cover {
  width: 100%;
  height: calc(100vh - 45px);
  background-image: url("/img/season-cover-23.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.blend {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
}

.caption {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
}

.caption span.border {
  background-color: #111;
  color: #fff;
  --padding: 18px;
  font-size: 25px;
  letter-spacing: 10px;
}

#edit-page-map {
  height: 500px;
}

.atlas-settings {
  height: 7vh;
}

#atlas-page-map {
  height: calc(100vh - 45px);
}

.atlas-item-fields {
  display: inline-block;
  max-width: calc(100% - 110px);
}

.nopadding {
  padding: 0 !important;
}

.atlas-road-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 30%;
}

.atlas-road-list::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 30%;
}

.atlas-road-list::-webkit-scrollbar-thumb {
  background-color: #2748ff;
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.5, transparent),
    to(transparent)
  );
}

.atlas-sort-table {
  margin-bottom: 0;
  border-bottom: 1px solid #4f4f4f;
}

.atlas-sort-table td {
  vertical-align: middle;
}

#more-content {
  padding-top: 30px;
}

.main-feature {
  text-align: center;
}

.main-feature img {
  width: 120px;
  margin: 10px;
}

.small-feature {
  text-align: center;
  padding: 2px;
}

.small-feature img {
  height: 80px;
  width: 80px;
  margin: 0px;
}

.map-wrapper {
  position: relative;
  text-align: center;
}

#controls {
  top: 10px;
  left: 110px;
  width: 360px;
  height: 45px;
}

#data-box {
  top: 10px;
  left: 500px;
  height: 45px;
  line-height: 45px;
  display: none;
}

#census-variable {
  width: 360px;
  height: 20px;
}

.nicebox {
  position: absolute;
  text-align: center;
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 13px;
  z-index: 5;
  box-shadow: 0 4px 6px -4px #333;
  padding: 5px 10px;
  background: white;
  background: linear-gradient(to bottom, white 0%, whitesmoke 100%);
  border: #e5e5e5 1px solid;
}

#legend {
  display: flex;
  display: -webkit-box;
  padding-top: 7px;
}

.color-key {
  background: linear-gradient(
    to right,
    #db4639 0%,
    #db7f29 17%,
    #d1bf1f 33%,
    #92c51b 50%,
    #48ba17 67%,
    #12ab24 83%,
    #0f9f59 100%
  );
  flex: 1;
  -webkit-box-flex: 1;
  margin: 0 5px;
  text-align: left;
  font-size: 1em;
  line-height: 1em;
}

#data-value {
  font-size: 2em;
  font-weight: bold;
}

#data-label {
  font-size: 2em;
  font-weight: normal;
  padding-right: 10px;
}

#data-label:after {
  content: ":";
}

#data-caret {
  margin-left: -5px;
  display: none;
  font-size: 14px;
  width: 14px;
}

.map-button {
  background-color: #2748ff;
  cursor: pointer;
}

.map-button img {
  width: 24px;
  height: 24px;
}

.rotate-item {
  color: #2748ff;
  -animation: spin 2s infinite linear;
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#wrapper {
  position: relative;
}

#over_map {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  top: 0px;
  left: 0px;
  z-index: 99;
}

.comment-detail {
  font-size: 1.2em;
}

.comment-road-panel {
  background-color: lightgray;
}

.featue-images {
  height: 160px;
  width: 200px;
  border-radius: 50% !important;
  background-color: #343a40;
  display: inline-block;
}

.featue-images img {
  margin: 0 5px;
  top: 48px;
  position: relative;
}

.no-margin p {
  margin-bottom: 0;
  margin-top: 0;
}

.image-upload > input {
  display: none;
}

.image-upload img {
  cursor: pointer;
}

.map-record-preview-border {
  border: 1px solid #2748ff;
  border-radius: 0.25rem;
}

.map-record-preview {
  background-color: white;
  font-size: 1.5em;
  text-decoration: none;
  padding: 0.375rem 0.75rem;
  padding-right: 30px;
}

.map-record-preview div {
  margin: 0px;
}

.rc-tooltip {
  z-index: 1500;
}

.rc-slider-tooltip {
  z-index: 1500;
}

[data-toggle="collapse"] .fa:before {
  content: " ▲";
}

[data-toggle="collapse"].collapsed .fa:before {
  content: " ▼";
}

.align-middle td {
  vertical-align: middle;
}

.img-max-100 {
  max-width: 100px;
  max-height: 100px;
}

.navbar-toggler {
  margin: 2.5px;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  animation: rotate 1s linear 0s infinite;
  top: 50%;
  left: 50%;
  position: absolute;
}

.landing-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  padding-top: 3em;
  padding-bottom: 3em;
}

.landing-wrapper h1 {
  font-weight: 400;
}

.navbar-brand {
  text-transform: uppercase;
}

.navbar-brand img {
  margin-left: 3px;
}

.navbar-nav {
  font-family: Helvetica, Arial, sans-serif;
}

.t-content-wrapper {
  -webkit-box-shadow: inset 0px -0.5em 0.5em 0.5em rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset 0px 0.5em 0.5em -0.5em rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 0.5em 0.5em -0.5em rgba(0, 0, 0, 0.5);
  height: 0.5em;
  position: absolute;
  width: 100%;
  z-index: 99;
}

.menu-offset {
  margin-top: 45px;
}

.user-interaction {
  cursor: pointer;
}

.user-interaction svg {
  cursor: pointer;
  color: #2748ff;
}

.primary {
  color: #2748ff;
}

.rc-slider-track {
  background-color: #2748ff;
}

.rc-slider-handle {
  border: solid 2px #2748ff;
}

.rc-slider-handle:hover {
  border-color: #003df5;
}

.rc-slider-handle:active {
  border-color: #003df5;
  box-shadow: 0 0 5px #003df5;
}

.rc-slider-dot-active {
  border-color: #003df5;
}

.filter-selection span {
  display: inline-block;
  background-color: #003df538;
  font-size: 0.8em;
  border-bottom-left-radius: 0.8em;
  border-top-left-radius: 0.8em;
  border-bottom-right-radius: 0.8em;
  border-top-right-radius: 0.8em;
  padding: 0 0.8em;
  margin: 0 0.3em;
}

.fs-0 {
  font-size: 0.8em;
}

.fs-1 {
  font-size: 1em;
}

.fs-2 {
  font-size: 1.5em;
}

.fs-3 {
  font-size: 2em;
}

.fs-4 {
  font-size: 2.5em;
}

.c-pointer {
  cursor: pointer;
}

.content-wrapper {
  margin-top: 2em;
  margin-bottom: 2em;
}

.white {
  color: white;
}

.add-guide div.collapse:not(.show) {
  display: block;
  height: 1.5rem;
  overflow: hidden;
}

.add-guide div.collapsing {
  height: 1.5rem;
}

.rating-table td {
  vertical-align: middle;
}

.single-gallery-span {
  width: 2em;
  height: 2em;
  text-align: center;
  font-size: 1.5em;
  line-height: 1.5em;
  color: #2748ff;
  border-radius: 1.5em;
  border: 1px solid #2748ff;
  background-color: rgba(255, 255, 255, 0.6);
}

.single-gallery-span:hover {
  color: white;
  background-color: #2748ff;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

/*# sourceMappingURL=styles.css.map */

/* The Modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  display: block;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

.season-dropdown {
  cursor: pointer;
}

.current-season-item {
  padding-right: 0px !important;
}